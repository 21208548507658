import AOS from "aos";
import lozad from "lozad";
import { setBackgroundElement, detectCloseElement, buttonToTop, clickScrollToDiv, appendCaptchaASP, ToggleItem, countUpInit } from "./helper";

import { header } from "./header";
import { swiperInit } from "./swiper";

import { showMenu } from "./header-menu";
import menuspy from "menuspy";
$(document).ready(function () {
	setBackgroundElement();
	header.init();
	swiperInit();
	showMenu();
	ToggleItem();
	countUpInit();
	initMenuSpy();
	hoverItemHome8();
});

function hoverItemHome8() {
	// Initially show first image and hide others
	$(".circle-image img:first").addClass("opacity-100 visible").removeClass("invisible opacity-0");
	$(".circle-image img:not(:first)").addClass("invisible opacity-0").removeClass("opacity-100 visible");

	// Initially activate first quarter
	const $firstQuarter = $(".circle-quarter-top-left");
	$firstQuarter.addClass("active");
	$firstQuarter.find(".item-circle-text").addClass("active");

	// Handle hover on quarters
	$('.circle-border [class^="circle-quarter"]').hover(function () {
		// Mouse enter
		const targetIndex = $(this).data("index");

		// Remove active states from all quarters
		$('.circle-border [class^="circle-quarter"]').removeClass("active");
		$(".item-circle-text").removeClass("active");

		// Add active state to current quarter
		$(this).addClass("active");
		$(this).find(".item-circle-text").addClass("active");

		// Hide all images first
		$(".circle-image img").addClass("invisible opacity-0").removeClass("opacity-100 visible");

		// Show corresponding image
		$(`.circle-image img[data-index="${targetIndex}"]`).addClass("opacity-100 visible").removeClass("invisible opacity-0");
	});
}

$(".tabs").tabslet({
	active: 2,
	animation: true,
});
$(".tabs-secondary").tabslet({
	animation: true,
});

function initMenuSpy() {
	if ($("#main-company").length > 0) {
		var elm = document.querySelector("#main-company");
		var ms = new menuspy(elm, {
			activeClass: "current-item",
			threshold: 160,
		});
	}
}

// Home-4 section hover effects
$(document).ready(function () {
	// Initially show first image and activate first item
	$("#image-1").removeClass("invisible opacity-0").addClass("opacity-100 visible");
	const $firstItem = $(".home-4-left").first();

	// Set active styles for first item
	$firstItem.find("h3").css("color", "#150f96");
	$firstItem.find(".home-4-left-item").css("borderTopColor", "#150f96");

	// Show content of first item
	$firstItem.find(".home-4-left-item-content").removeClass("h-0 opacity-0 invisible").addClass("h-auto visible opacity-100 mt-5");

	// Handle hover on h3
	$(".home-4-left-item h3").hover(function () {
		const $parentGroup = $(this).closest(".group");
		const targetImage = $parentGroup.data("target");

		// Remove active styles from all items
		$(".home-4-left-item h3").css("color", "");
		$(".home-4-left-item").css("borderTopColor", "");

		// Add active styles to current item
		$(this).css("color", "#150f96");
		$(this).closest(".home-4-left-item").css("borderTopColor", "#150f96");

		// Hide all other contents first
		$(".home-4-left-item-content").addClass("h-0 opacity-0 invisible").removeClass("h-auto visible opacity-100 mt-5");

		// Update images
		$(".home-4-right").addClass("invisible opacity-0").removeClass("opacity-100 visible");
		$(`#${targetImage}`).removeClass("invisible opacity-0").addClass("opacity-100 visible");

		// Show content of current item
		const $content = $(this).siblings(".home-4-left-item-content");
		$content.removeClass("h-0 opacity-0 invisible").addClass("h-auto visible opacity-100 mt-5");
	});
});

/*==================== Aos Init ====================*/
AOS.init({
	offset: 100,
});
/*==================== Lazyload JS ====================*/
const observer = lozad(); // lazy loads elements with default selector as '.lozad'
observer.observe();

document.addEventListener("DOMContentLoaded", function () {
	Fancybox.bind("[data-fancybox]", {
		dragToClose: false,
		backdropClick: false,
		template: {
			closeButton: '<button class="fancybox-button fancybox-button--close" title="Close"><i class="fa-duotone fa-solid fa-xmark"></i></button>',
		},
	});
});
