import Swiper from "swiper";
import { Autoplay, EffectFade, FreeMode, Navigation, Pagination, Scrollbar } from "swiper/modules";
/**
 * @param swiperInit
 */
export function swiperInit() {
	// new Swiper(".section-home-banner .swiper", {
	// 	slidesPerView: 1,
	// 	spaceBetween: 0,
	// 	speed: 1000,
	// 	loop: true,
	// 	effect: "fade",
	// 	autoplay: {
	// 		delay: 3500,
	// 	},
	// 	modules: [Pagination, Navigation, Autoplay, EffectFade],
	// 	pagination: {
	// 		el: ".section-home-banner .swiper-pagination",
	// 		clickable: true,
	// 		renderBullet: function (index, className) {
	// 			const slide = this.slides[index];
	// 			const title = slide.getAttribute("data-title") || `Slide ${index + 1}`;
	// 			return `<span class="${className}">${title}</span>`;
	// 		},
	// 	},
	// 	navigation: {
	// 		nextEl: ".section-home-banner .btn-next",
	// 		prevEl: ".section-home-banner .btn-prev",
	// 	},
	// });
	// // Reuseable Swiper
	// $(".swiper-cols-1 .swiper").each(function () {
	// 	new Swiper(this, {
	// 		slidesPerView: 1,
	// 		spaceBetween: 20,
	// 		modules: [Navigation],
	// 		navigation: {
	// 			nextEl: $(this).closest(".swiper-cols-1").find(".btn-next")[0],
	// 			prevEl: $(this).closest(".swiper-cols-1").find(".btn-prev")[0],
	// 		},
	// 	});
	// });
	// $(".swiper-cols-2 .swiper").each(function () {
	// 	new Swiper(this, {
	// 		slidesPerView: 1,
	// 		spaceBetween: 20,
	// 		modules: [Navigation],
	// 		navigation: {
	// 			nextEl: $(this).closest(".swiper-cols-1").find(".btn-next")[0],
	// 			prevEl: $(this).closest(".swiper-cols-1").find(".btn-prev")[0],
	// 		},
	// 		breakpoints: {
	// 			768: {
	// 				slidesPerView: 2,
	// 				spaceBetween: 40,
	// 			},
	// 		},
	// 	});
	// });
	// $(".swiper-cols-3 .swiper").each(function () {
	// 	new Swiper(this, {
	// 		slidesPerView: 1,
	// 		spaceBetween: 20,
	// 		modules: [Navigation],
	// 		navigation: {
	// 			nextEl: $(this).closest(".swiper-cols-3").find(".btn-next")[0],
	// 			prevEl: $(this).closest(".swiper-cols-3").find(".btn-prev")[0],
	// 		},
	// 		breakpoints: {
	// 			768: {
	// 				slidesPerView: 2,
	// 			},
	// 			1200: {
	// 				slidesPerView: 3,
	// 				spaceBetween: 40,
	// 			},
	// 		},
	// 	});
	// });
	// $(".swiper-cols-4 .swiper").each(function () {
	// 	new Swiper(this, {
	// 		slidesPerView: 1,
	// 		spaceBetween: 20,
	// 		modules: [Navigation],
	// 		navigation: {
	// 			nextEl: $(this).closest(".swiper-cols-4").find(".btn-next")[0],
	// 			prevEl: $(this).closest(".swiper-cols-4").find(".btn-prev")[0],
	// 		},
	// 		breakpoints: {
	// 			768: {
	// 				slidesPerView: 3,
	// 			},
	// 			1200: {
	// 				slidesPerView: 4,
	// 				spaceBetween: 40,
	// 			},
	// 		},
	// 	});
	// });

	swiperProjectDetail();
	swiperCareerDetail();
	swiperHomeBanner();
	swiperNews();
	swiperHome3();
	swiperHome7();
	swiperHomeBannerMobile();
}
function swiperHomeBannerMobile() {
	const homeSwiperMobile = new Swiper(".swiper-home-banner-mobile", {
		modules: [Autoplay, Navigation, Pagination],
		speed: 1000,
		slidesPerView: 1,
		pagination: {
			el: ".swiper-pagination-mobile",
			clickable: true,
		},
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
	});
}

function swiperHome3() {
	const swiperHome3 = new Swiper(".home-3-swiper", {
		modules: [Autoplay, Navigation, Scrollbar, FreeMode],
		spaceBetween: 40,
		slidesPerView: 1,
		speed: 1200,
		freeMode: true,
		grabCursor: true,
		scrollbar: {
			el: ".swiper-scrollbar",
			hide: false,
		},
		breakpoints: {
			640: {
				slidesPerView: 2,
				spaceBetween: 20,
			},
			768: {
				slidesPerView: 2,
				spaceBetween: 40,
			},
			1024: {
				slidesPerView: 5,
				spaceBetween: 5,
			},
			1920: {
				slidesPerView: 5,
				spaceBetween: 5,
			},
		},
	});
}

function swiperHome7() {
	const swiperHome3 = new Swiper(".home-7-swiper", {
		modules: [Autoplay, Navigation, Scrollbar, FreeMode],

		slidesPerView: 1,
		speed: 1200,
		freeMode: true,
		grabCursor: true,
		scrollbar: {
			el: ".swiper-scrollbar",
			hide: false,
		},
		breakpoints: {
			640: {
				slidesPerView: 2,
				spaceBetween: 20,
			},
			768: {
				slidesPerView: 2,
			},
			1024: {
				slidesPerView: 3.8,
			},
		},
	});
}

function swiperHomeBanner() {
	// Check if pagination elements exist
	const paginationItems = document.querySelectorAll(".pagination-main");
	if (!paginationItems || paginationItems.length === 0) return;

	// Update pagination active state
	function updatePagination(activeIndex) {
		paginationItems.forEach((item, index) => {
			const paginationItem = item.querySelector(".pagination-item");
			const paginationName = item.querySelector(".pagination-name");

			if (index === activeIndex) {
				paginationItem.classList.add("active");
				paginationName.classList.add("active");
			} else {
				paginationItem.classList.remove("active");
				paginationName.classList.remove("active");
			}
		});
	}

	// Initialize Swiper
	const homeSwiper = new Swiper(".swiper-home-banner", {
		modules: [Autoplay, Navigation, EffectFade],
		effect: "fade",
		speed: 1000,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
		},
		on: {
			init: function () {
				updatePagination(0);
			},
			slideChange: function () {
				updatePagination(this.realIndex);
			},
		},
	});

	// Handle pagination clicks
	paginationItems.forEach((item, index) => {
		item.addEventListener("click", () => {
			homeSwiper.slideToLoop(index);
		});
	});
}

function swiperProjectDetail() {
	const swiperSingleProject = new Swiper(".project-swiper", {
		modules: [Autoplay, Navigation],
		spaceBetween: 20,
		slidesPerView: 1.65,
		freeMode: true,
		initialSlide: 1,
		speed: 1500,
		loop: true,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
		centeredSlides: true,
		navigation: {
			nextEl: ".custom-next, .btn-prev-project",
			prevEl: ".custom-prev, .btn-next-project",
		},
		breakpoints: {
			640: {
				slidesPerView: 1.2,
				spaceBetween: 20,
			},
			768: {
				slidesPerView: 1.55,
				spaceBetween: 60,
			},
			1024: {
				slidesPerView: 1.55,
				spaceBetween: 60,
			},
			1920: {
				slidesPerView: 1.55,
				spaceBetween: 75,
			},
		},
	});

	const swiperProjectDetail = new Swiper(".project-detail-swiper", {
		modules: [Autoplay, Navigation],
		spaceBetween: 40,
		slidesPerView: 1,
		speed: 1500,
		loop: true,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
		navigation: {
			nextEl: ".btn-swiper-right",
			prevEl: ".btn-swiper-left",
		},
		breakpoints: {
			640: {
				slidesPerView: 2,
				spaceBetween: 20,
			},
			768: {
				slidesPerView: 2,
				spaceBetween: 40,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 40,
			},
			1920: {
				slidesPerView: 3,
				spaceBetween: 40,
			},
		},
	});
}

function swiperCareerDetail() {
	const swiperCareerDetail = new Swiper(".career-detail-swiper", {
		modules: [Autoplay, Navigation],
		spaceBetween: 40,
		slidesPerView: 1,
		loop: true,
		speed: 1200,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
		navigation: {
			nextEl: ".btn-career-next, .btn-career-next-mobile",
			prevEl: ".btn-career-prev, .btn-career-prev-mobile",
		},
		breakpoints: {
			640: {
				slidesPerView: 2,
				spaceBetween: 20,
			},
			768: {
				slidesPerView: 2,
				spaceBetween: 40,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 40,
			},
			1920: {
				slidesPerView: 3,
				spaceBetween: 40,
			},
		},
	});
}

function swiperNews() {
	const swiperCareerDetail = new Swiper(".news-swiper", {
		modules: [Autoplay, Navigation],
		spaceBetween: 20,
		slidesPerView: 1,
		autoplay: true,
		loop: true,
		speed: 1200,
		navigation: {
			nextEl: ".btn-news-next",
			prevEl: ".btn-news-prev",
		},
		breakpoints: {
			640: {
				slidesPerView: 2,
				spaceBetween: 20,
			},
			768: {
				slidesPerView: 2,
				spaceBetween: 40,
			},
			1024: {
				slidesPerView: 4,
				spaceBetween: 40,
			},
			1920: {
				slidesPerView: 4,
				spaceBetween: 40,
			},
		},
	});
}
